import firebase from 'firebase';
import {  all, call, put, take, takeLatest } from "redux-saga/effects";

import { Creators, Types } from ".";
import { rsf } from "src/config/firebase";
import { subdomain } from "src/config/domain";

export default function* placesRoot(){
  yield all([
    takeLatest(Types.PLACES_REQUEST, syncPlaces),
    takeLatest(Types.PLACES_CREATE, placeCreate)
  ])
}

export function* syncPlaces() {
  const type: any = 'collection';
  const channel = rsf.firestore.channel(`tenants/${subdomain}/places`, type);

  while (true) {        
    const snapshot: firebase.firestore.QuerySnapshot = yield take(channel);
    const places = snapshot.docs.map(placesSerialize);
    yield put(Creators.placesSuccess(places));
  }
}

export const placesSerialize = (doc: firebase.firestore.DocumentSnapshot) => ({
  properties: getPlaceData(doc),
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: getCoordinates(doc.data()),
  },
});


const getPlaceData = (doc: firebase.firestore.DocumentSnapshot) => {
  const { name, description, video, type} = doc.data() || {};
  return {
    cluster: false,
    ref: doc.id,
    name,
    type,
    video,
    description,
  };
};

const getCoordinates = (data: any) => {
  const { lng, lat } = data;
  return lng && lat
    ? [parseFloat(lng), parseFloat(lat)]
    : [-48.425202683266434, -23.15937550746007];
};

export function* placeCreate(action:any){
  const { place } = action
  yield call(
    rsf.firestore.addDocument,
    `tenants/${subdomain}/places`,
    place,    
  );
}