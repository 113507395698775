import React, { useState } from 'react';
import styled from 'styled-components';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';

import { RootState } from 'src/store';
import ImportData from './ImportData';
import { Creators } from 'src/store/lots';
import checkRole from 'src/utils/CheckRole';
import { Creators as UserCreators } from 'src/store/user';
// import CreatePlace from './CreatePlace';

const Config = () => {
  const dispatch = useDispatch();
  const [premium, setPremium] = useState('');
  const [normal, setNormal] = useState('');
  const { updating, progress } = useSelector((state: RootState) => state.lots);
  const { photoURL, displayName, role } = useSelector(
    (state: RootState) => state.user.data,
  );

  const handleSaveData = (data: any) => {
    dispatch(Creators.saveLots(data));
  };

  const handleLogout = () => {
    dispatch(UserCreators.logoutRequest());
  };

  const savePremium = () => {
    dispatch(
      Creators.updateCondominium({
        condominio: parseFloat(premium.replace(',', '.')).toFixed(2),
        type: 'premium',
      }),
    );
  };

  const saveNormal = () => {
    dispatch(
      Creators.updateCondominium({
        condominio: parseFloat(normal.replace(',', '.')).toFixed(2),
        type: 'normal',
      }),
    );
  };

  return (
    <Container>
      <Controls>
        {checkRole(role, ['admin']) && (
          <>
            {updating ? (
              <CircularProgressbar
                styles={{
                  path: {
                    stroke: 'green',
                  },
                  text: {
                    fill: 'green',
                    fontSize: '16px',
                  },
                }}
                value={progress}
                text={`${progress}%`}
              />
            ) : (
              <>
                <ImportData saveData={handleSaveData} />
                <Form>
                  <p>Atualizar condominios</p>
                  <Row>
                    <Input
                      type="text"
                      value={premium}
                      onChange={(e) => setPremium(e.currentTarget.value)}
                    />

                    <SaveButton onClick={savePremium}>
                      Atualizar premium
                    </SaveButton>
                  </Row>
                  <Row>
                    <Input
                      type="text"
                      value={normal}
                      onChange={(e) => setNormal(e.currentTarget.value)}
                    />
                    <SaveButton onClick={saveNormal}>
                      Atualizar normal
                    </SaveButton>
                  </Row>
                </Form>
                {/* <CreatePlace /> */}
              </>
            )}
          </>
        )}
      </Controls>
      <UserContainer>
        <Avatar src={photoURL} />
        <Name>{displayName}</Name>
        <LogoutButton onClick={handleLogout}>Sair</LogoutButton>
      </UserContainer>
    </Container>
  );
};

export default Config;

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const Name = styled.h3``;

const LogoutButton = styled.button`
  background: none;
  padding: 5px 15px;
  cursor: pointer;
  border: none;
  :hover {
    background-color: #f1f1f1;
  }
`;

const Controls = styled.div`
  flex: 1;
`;

const UserContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  padding: 3px 5px;
  border: 1px solid #999;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const SaveButton = styled.button`
  padding: 3px 5px;
  border: 1px solid #999;
  background-color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  :hover {
    cursor: pointer;
    background-color: #eee;
  }
`;

const Form = styled.div`
  margin-top: 20px;
`;

const Row = styled.div`
  padding: 5px 0;
`;
