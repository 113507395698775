import { format } from 'date-fns';
import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';

import ptBR from 'date-fns/locale/pt-BR';
import {
  AiOutlineEdit,
  AiOutlineHeart,
  AiOutlineLogin,
  AiOutlineMenu,
  AiOutlineSearch,
  AiOutlineUnorderedList,
} from 'react-icons/ai';
import { BiFilterAlt } from 'react-icons/bi';
import { RiRoadMapLine } from 'react-icons/ri';
import { TiMap } from 'react-icons/ti';
import { useHistory, useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import calcPrice from 'src/utils/CalcPrice';
import checkRole from 'src/utils/CheckRole';
import isPremium from 'src/utils/PremiumLots';
import type { RootState } from '../store';
import { Creators, ILot } from '../store/lots';
import { Creators as UserCreators } from '../store/user';
import Config from './Config';
import FiltersForm from './FiltersForm';
import SearchForm from './SearchForm';
import TableHeader from './TableHeader';

const OPTIONS = {
  threshold: 0.1,
  keys: ['properties.ref', 'properties.tamanho'],
};

const selectIsActive = createSelector(
  (state: RootState) => state.lots,
  ({ active, lots }) => lots.find((lot) => lot.properties.ref === active),
);

const Sidebar = () => {
  const [searching, setSearching] = useState(false);
  const [searchResult, setSearchResult] = useState<any>(null);
  const [filtering, setFiltering] = useState(false);
  const [filterResult, setFilterResult] = useState<any>(null);
  const [menuVisible, setMenuVisible] = useState('list');
  const lots = useSelector((state: RootState) => state.lots.lots);
  const active = useSelector(selectIsActive);
  const { data: user, logged, logging } = useSelector(
    (state: RootState) => state.user,
  );
  const tenant = useSelector((state: RootState) => state.tenant.data);
  const [hide, setHide] = useState(false);
  const { role } = useSelector((state: RootState) => state.user.data || {});
  const dispatch = useDispatch();
  const history = useHistory();

  const fuse = new Fuse(lots, OPTIONS);

  let lotsToShow: ILot[] = [];
  const filterAvailable = (lot: ILot) =>
    lot.properties.favorited && lot.properties.status === 'available';
  if (menuVisible === 'list') {
    lotsToShow =
      searchResult?.filter(
        (lot: ILot) => lot.properties.status === 'available',
      ) ||
      filterResult?.filter(
        (lot: ILot) => lot.properties.status === 'available',
      ) ||
      lots?.filter((lot: ILot) => lot.properties.status === 'available');
  } else if (menuVisible === 'edit') {
    lotsToShow = searchResult || filterResult || lots;
  } else if (menuVisible === 'favorite') {
    lotsToShow = searchResult
      ? searchResult.filter(filterAvailable)
      : filterResult
      ? filterResult.filter(filterAvailable)
      : lots.filter(filterAvailable);
  }

  const handleChange = _.debounce((value) => {
    const list = value
      ? fuse.search(value).map(({ item }) => ({ ...item }))
      : lots;
    setSearchResult(list);
  }, 500);

  const handleMouseOver = (lot: ILot) => {
    const {
      properties: { ref },
    } = lot;
    dispatch(Creators.setHovered(ref));
  };

  const handleOnClick = (lot: ILot) => {
    const {
      properties: { ref },
    } = lot;
    dispatch(Creators.setActive(ref));
    history.push(`/lote/${ref}`);
  };

  const handleClose = () => {
    setSearching(false);
    setSearchResult(null);
  };

  const handleFiltersChange = ({ value }: any) => {
    const order = value % 2 === 0 ? 'asc' : 'desc';
    const properties = [
      'valor',
      'valor',
      'tamanho',
      'tamanho',
      'custo',
      'custo',
      'lote',
      'lote',
    ];
    const key = properties[value];
    setFilterResult(() =>
      _.orderBy(lots, (lot: any) => parseFloat(lot.properties[key]), [order]),
    );
    setFiltering(false);
  };

  const handleFiltersClose = () => {
    setFiltering(false);
  };

  const handleSidebarOpen = () => {
    setHide(false);
    dispatch(Creators.setActive(null));
    history.push('/');
  };

  const handleLogin = () => {
    dispatch(UserCreators.loginRequest());
  };

  const editHeaderItems = [
    { label: 'lote' },
    { label: 'liberado' },
    { label: 'vendido' },
  ];

  return (
    <>
      {(hide || active) && (
        <OpenMenuButton onClick={handleSidebarOpen}>
          <AiOutlineMenu />
        </OpenMenuButton>
      )}
      <MainHeader open={hide || active ? false : true}>
        <Logo>
          <TiMap />
          {tenant?.name && <TenantName>{tenant.name}</TenantName>}
        </Logo>
        <ButtonsContainer>
          <IconButton onClick={() => setFiltering((prev) => !prev)}>
            <BiFilterAlt />
          </IconButton>
          <IconButton onClick={() => setSearching((prev) => !prev)}>
            <AiOutlineSearch />
          </IconButton>
        </ButtonsContainer>
        <SearchForm
          open={searching}
          handleChange={handleChange}
          handleClose={handleClose}
        />
        <FiltersForm
          open={filtering}
          handleChange={handleFiltersChange}
          handleClose={handleFiltersClose}
        />
      </MainHeader>

      <SidebarContainer open={hide || active ? false : true}>
        {menuVisible === 'list' && (
          <>
            <TableHeader open={hide || active ? false : true} />
            <List
              hide={hide}
              active={active}
              lots={lotsToShow}
              handleMouseOver={handleMouseOver}
              handleOnClick={handleOnClick}
            />
          </>
        )}
        {menuVisible === 'edit' && (
          <>
            <TableHeader
              open={hide || active ? false : true}
              items={editHeaderItems}
            />
            <Edit
              hide={hide}
              active={active}
              lots={lotsToShow}
              handleMouseOver={handleMouseOver}
              handleOnClick={handleOnClick}
            />
          </>
        )}
        {menuVisible === 'favorite' && (
          <>
            <TableHeader open={hide || active ? false : true} />
            <Favorites
              hide={hide}
              active={active}
              lots={lotsToShow}
              handleMouseOver={handleMouseOver}
              handleOnClick={handleOnClick}
            />
          </>
        )}
        {menuVisible === 'config' && <Config />}
      </SidebarContainer>
      <InfoBar open={hide || active ? false : true}>
        <p>{lotsToShow.length} Lotes</p>
        <p>
          Atualizado em{' '}
          {tenant?.updatedAt?.toDate() &&
            format(tenant?.updatedAt?.toDate(), 'dd/MM HH:mm', {
              locale: ptBR,
            })}
        </p>
      </InfoBar>
      <ButtonsActions open={hide || active ? false : true}>
        <IconButton onClick={() => setMenuVisible('list')}>
          <AiOutlineUnorderedList />
        </IconButton>
        {checkRole(role, ['admin', 'assistant']) && (
          <IconButton onClick={() => setMenuVisible('edit')}>
            <AiOutlineEdit />
          </IconButton>
        )}
        {role && (
          <IconButton onClick={() => setMenuVisible('favorite')}>
            <AiOutlineHeart />
          </IconButton>
        )}
        <IconButton onClick={() => setHide(true)}>
          <RiRoadMapLine />
        </IconButton>
        <IconButton
          onClick={() => (!logged ? handleLogin() : setMenuVisible('config'))}
        >
          {logged ? (
            <Avatar src={user?.photoURL} />
          ) : logging ? (
            <LoaderSpinnerLogin />
          ) : (
            <AiOutlineLogin />
          )}
        </IconButton>
      </ButtonsActions>
      <SelectActiveLot />
    </>
  );
};

const SelectActiveLot = () => {
  let params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Creators.setActive(null));
    dispatch(Creators.setHovered(''));
  }, [params, dispatch]);
  return null;
};

export default Sidebar;

const List = ({ lots, handleMouseOver, handleOnClick }: any) => {
  return (
    <div className="lot-name">
      {lots.map((lot: ILot) => {
        const { lote, valor } = lot.properties;
        const entryNumber = isPremium(lot.properties.ref) ? 0.09 : 0.05;
        const entradaNumber = parseFloat(valor || '') * entryNumber;
        const valorNumber = parseFloat(valor || '');

        return (
          <ListItem
            key={lote}
            onMouseOver={() => handleMouseOver(lot)}
            onClick={() => handleOnClick(lot)}
          >
            <ItemTitle>{lot.properties.lote}</ItemTitle>
            <ItemText small={true}>
              {parseInt(lot.properties.tamanho)}m²
            </ItemText>
            <ItemText>
              {entradaNumber.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </ItemText>
            <ItemText>
              {calcPrice(
                valorNumber - entradaNumber,
                120,
                0.166919,
              ).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </ItemText>
          </ListItem>
        );
      })}
    </div>
  );
};

const Edit = ({ lots, handleMouseOver }: any) => {
  const dispatch = useDispatch();
  const handleChange = (ref: string, status: string) => {
    dispatch(Creators.updateStatus(ref, status));
  };
  return (
    <div>
      {lots.map((lot: ILot) => {
        return (
          <ListItem
            key={lot.properties.lote}
            onMouseOver={() => handleMouseOver(lot)}
          >
            <ItemTitle>{lot.properties.lote}</ItemTitle>
            <div>
              <input
                type="radio"
                id="available"
                defaultChecked={lot.properties.status === 'available'}
                name={`${lot.properties.ref}-status`}
                value="available"
                onChange={(e) => {
                  console.log('avaliable', lot);
                  handleChange(lot.properties.ref, e.target.value);
                }}
              />
            </div>

            <div>
              <input
                type="radio"
                id="sold"
                defaultChecked={lot.properties.status === 'sold'}
                name={`${lot.properties.ref}-status`}
                value="sold"
                onChange={(e) => {
                  console.log('sold', lot);
                  handleChange(lot.properties.ref, e.target.value);
                }}
              />
            </div>
          </ListItem>
        );
      })}
    </div>
  );
};

const Favorites = ({ lots, handleMouseOver, handleOnClick }: any) => {
  return (
    <div>
      {lots.length <= 0 && <Empty>Sem favoritos!</Empty>}
      {lots.map((lot: ILot) => {
        const { parcela, iptu, lote } = lot.properties;
        const parcelaNumber = parseFloat(parcela || '');
        const iptuNumber = parseFloat(iptu || '');

        return (
          <ListItem
            key={lote}
            onMouseOver={() => handleMouseOver(lot)}
            onClick={() => handleOnClick(lot)}
          >
            <ItemTitle>{lot.properties.lote}</ItemTitle>
            <ItemText small={true}>
              {parseInt(lot.properties.tamanho)}m²
            </ItemText>
            <ItemText>
              {(parcelaNumber + iptuNumber + 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </ItemText>
            <ItemText>
              {parseFloat(lot.properties.valor).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </ItemText>
          </ListItem>
        );
      })}
    </div>
  );
};

const OpenMenuButton = styled.button`
  top: 10px;
  left: 10px;
  width: 40px;
  border: none;
  z-index: 999;
  height: 40px;
  outline: none;
  display: flex;
  color: #000;
  position: fixed;
  border-radius: 50%;
  align-items: center;
  font-size: 22px;
  background: #f1f1f1;
  justify-content: center;
  box-shadow: 2px 3px 5px #969494;
`;

const SidebarContainer = styled.div<{ open: boolean }>`
  left: 0;
  top: 0;
  z-index: 999;
  width: 540px;
  max-width: 100%;
  height: 100vh;
  position: fixed;
  overflow-x: hidden;
  padding-top: 95px;
  padding-bottom: 85px;
  overflow-y: scroll;
  box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  background: #fff;
  transform: translateX(${(props) => (props.open ? 0 : '-100vw')});
`;

const MainHeader = styled.div<{ open: boolean }>`
  left: 0;
  top: 0px;
  width: 540px;
  display: flex;
  z-index: 9999;
  color: #fff;
  padding: 10px 0;
  position: fixed;
  max-width: 100%;
  transition: 0.5s;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  background: green;
  transform: translateX(${(props) => (props.open ? 0 : '-100vw')});
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  font-size: 24px;
  h1 {
    font-size: 16px;
    font-weight: 700;
    margin: 0 5px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

const ListItem = styled.div`
  display: flex;
  padding: 13px 0;
  align-items: center;
  justify-content: space-between;
  transition: 0.5s;
  &:nth-child(odd) {
    background-color: #fff;
  }
  &:nth-child(even) {
    background-color: #f1f1f1;
  }
  :hover {
    background-color: #acb9ac;
    cursor: pointer;
  }
  div {
    flex: 2;
    display: flex;
    justify-content: center;
  }
`;

const ItemTitle = styled.h4`
  flex: 2;
  text-align: center;
`;

const ItemText = styled.p<{ small?: boolean }>`
  flex: ${(props) => (props.small ? 1 : 2)};
  font-size: 14px;
  font-weight: normal;
  text-align: center;
`;

const InfoBar = styled.div<{ open: boolean }>`
  left: 0;
  bottom: 51px;
  width: 540px;
  z-index: 999;
  padding: 5px;
  display: flex;
  position: fixed;
  font-size: 11px;
  max-width: 100%;
  transition: 0.5s;
  background: #fff;
  justify-content: space-evenly;
  box-shadow: -4px 5px 5px 5px #969696;
  border-bottom: 1px solid #f1f1f1;
  transform: translateX(${(props) => (props.open ? 0 : '-100vw')});
`;

const ButtonsActions = styled.div<{ open: boolean }>`
  left: 0;
  bottom: 0;
  padding: 10px 20px 5px;
  width: 540px;
  z-index: 999;
  display: flex;
  position: fixed;
  max-width: 100%;
  background: #fff;
  justify-content: space-between;
  transition: 0.5s;
  transform: translateX(${(props) => (props.open ? 0 : '-100vw')});
`;

const IconButton = styled.div`
  border: none;
  font-size: 25px;
  background: none;
  margin: 0 10px;
`;

const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoaderSpinnerLogin = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 5px solid #d0d0d0;
  border-top: 5px solid green;
  animation: ${spin} 2s linear infinite;
`;

const TenantName = styled.p`
  font-size: 18px;
  margin-left: 15px;
  font-weight: bold;
`;

const Empty = styled.p`
  text-align: center;
  font-size: 20px;
`;
