import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  height: 40px;
  width: 40px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  border: 6px solid #dedede; /* Light grey */
  border-top: 6px solid green; /* Blue */
  animation: ${spin} 2s linear infinite;
`;

export default Loader;
