import { fork } from 'redux-saga/effects';

import lotsRoot from './lots/sagas';
import tenantRoot from './tenant/sagas';
import uploadRoot from './upload/sagas';
import userRoot from './user/sagas';
import placesRoot from './places/sagas'

export default function* root() {
  yield fork(lotsRoot);
  yield fork(uploadRoot);
  yield fork(userRoot);
  yield fork(tenantRoot);
  yield fork(placesRoot);
}
