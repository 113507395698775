import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import calcPrice from 'src/utils/CalcPrice';
import kFormatter from 'src/utils/KFormater';
import styled from 'styled-components';
import { Creators } from '../store/lots';

interface Props {
  id: any;
  lat: any;
  lng: any;
  properties: any;
  featured?: boolean;
}

const Marker = ({ id, properties, featured }: Props) => {
  const { valor } = properties;
  const valorNumber = parseFloat(valor || '');
  const entradaNumber = parseFloat(valor || '') * 0.05;

  const history = useHistory();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(Creators.setActive(id));
    dispatch(Creators.toggleActive(false));
    dispatch(Creators.setHovered(id));
    history.push(`/lote/${id}`);
  };
  return (
    <Container featured={featured}>
      <Pin featured={featured} onClick={handleClick}>
        {kFormatter(calcPrice(valorNumber - entradaNumber, 120, 0.166919) + 0)}
      </Pin>
    </Container>
  );
};

export default Marker;

const Container = styled.div<{ featured?: boolean }>`
  position: absolute;
  z-index: ${(props) => (props.featured ? '9999' : '1')};
  transform: translate(-50%, -50%)
    scale(${(props) => (props.featured ? '1' : '0.75')});
`;

const Pin = styled.div<{ featured?: boolean }>`
  padding: 3px 10px;
  background: ${(props) => (props.featured ? '#FFF' : '#000')};
  color: ${(props) => (props.featured ? '#000' : '#FFF')};
  border-radius: 30px;
  font-size: 13px;
  box-shadow: 2px 3px 5px 2px #505050;
  font-weight: bold;
  box-sizing: border-box;
  transition: 0.3s ease-in-out;
  position: relative;
  cursor: pointer;
`;
