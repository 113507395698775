import React, { Component } from 'react';
import XLSX from 'xlsx';

const rowSerializer = (row) => ({
  lote: row?.id,
  tamanho: row?.area,
  rm: row?.squareMeter,
  valor: row?.total,
  entrada: row?.entry,
  parcela: row?.installment,
});

export default class ImportData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      excelData: {},
    };
  }
  excelToJson(reader) {
    let fileData = reader.result;
    let wb = XLSX.read(fileData, { type: 'binary' });
    let data = {};
    wb.SheetNames.forEach(function (sheetName) {
      let rowObj = XLSX.utils.sheet_to_row_object_array(wb.Sheets[sheetName]);
      data = rowObj.map(rowSerializer);
    });
    this.props.saveData(data);
  }
  loadFileXLSX(event) {
    var input = event.target;
    var reader = new FileReader();
    reader.onload = this.excelToJson.bind(this, reader);
    reader.readAsBinaryString(input.files[0]);
  }
  render() {
    return <input type="file" onChange={this.loadFileXLSX.bind(this)} />;
  }
}
