const full = window.location.hostname;

const parts = full.split('.');
let sub: any = null;
let domain: any = null;

switch (parts.length) {
  case 1:
    domain = parts[0];
    break;
  case 2:
    sub = parts[0];
    domain = parts[1];
    break;
  case 3:
    domain = parts[0];
    break;
  case 4:
    sub = parts[0];
    domain = parts[1];
    break;
  default:
    break;
}

let subdomain = sub ? sub : 'demo';

if (
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    full,
  )
) {
  subdomain = 'demo';
}

export { subdomain, full as fulldomain };
export default domain;
