import React, { FC } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BiSwim } from 'react-icons/bi';
import { AiOutlineShop } from 'react-icons/ai';

import { Creators } from 'src/store/places';

interface Props {
  lat: any;
  lng: any;
  id: string;
  type: string;
}

const PlaceMarker: FC<Props> = ({ type, id }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClick = () => {
    dispatch(Creators.setActive(id));
    dispatch(Creators.toggleActive(false));
    history.push(`/place/${id}`);
  };
  return (
    <Container>
      <Pin onClick={handleClick}>
        {type === 'market' && <AiOutlineShop size={20} />}
        {type === 'club' && <BiSwim size={20} />}
      </Pin>
    </Container>
  );
};

export default PlaceMarker;

const Container = styled.div`
  position: absolute;
`;

const Pin = styled.div`
  width: 30px;
  height: 30px;
  padding: 4px;
  display: flex;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  z-index: 99999999;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  transition: 0.3s ease-in-out;
  box-shadow: 2px 2px 4px 3px #565858;
`;
