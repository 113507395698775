import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { AiOutlineClose } from 'react-icons/ai';
import { Portal } from 'react-portal';

const options = [
  { value: 0, label: 'Menor preço' },
  { value: 1, label: 'Maior preço' },
  { value: 3, label: 'Maior área' },
  { value: 2, label: 'Menor área' },
  { value: 4, label: 'Menor valor m²' },
  { value: 5, label: 'Maior valor m²' },
  { value: 6, label: 'A-Z' },
  { value: 7, label: 'Z-A' },
];

const FiltersForm = ({ open, handleClose, handleChange }: any) => {
  return (
    <Portal>
      <Container open={open}>
        {open && (
          <>
            <Title>Ordenar</Title>
            <Select
              autoFocus={true}
              menuIsOpen={true}
              isSearchable={false}
              options={options}
              onChange={handleChange}
              placeholder="Ordenar por:"
            />
            <CloseButton onClick={handleClose}>
              <AiOutlineClose />
            </CloseButton>
          </>
        )}
      </Container>
    </Portal>
  );
};

export default FiltersForm;

const Container = styled.div<{ open: boolean }>`
  left: 0;
  top: 0px;
  width: 540px;
  height: 130px;
  z-index: 99999;
  position: fixed;
  padding: 15px;
  max-width: 100%;
  transition: 0.5s;
  color: #000;
  background-color: white;
  justify-content: space-between;
  box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.4);
  transform: translateY(${(props) => (props.open ? 0 : '-50vh')});
`;

const Title = styled.h3`
  margin: 5px 0 15px;
`;

const CloseButton = styled.button`
  color: #000;
  right: 15px;
  top: 20px;
  z-index: 9999;
  padding: 4px 6px;
  position: absolute;
  border: none;
  background: none;
  border-radius: 50%;
  cursor: pointer;
`;
