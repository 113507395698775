import React, { useEffect } from 'react';
import LogRocket from 'logrocket';
import { Provider } from 'react-redux';
import setupLogRocketReact from 'logrocket-react';
import { BrowserRouter } from 'react-router-dom';

import store from './config/redux';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { subdomain } from './config/domain';
import LayoutDefault from './layouts/LayoutDefault';
const Home = React.lazy(() => import('./views/Home'));

const AppToRender = () => {
  useEffect(() => {
    LogRocket.init('uv6uos/loteamentoclub');
    setupLogRocketReact(LogRocket);
  }, []);

  return (
    <React.Suspense fallback={<div>...</div>}>
      <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter>
            {subdomain === 'demo' ? (
              <LayoutDefault>
                <Home />
              </LayoutDefault>
            ) : (
              <App />
            )}
          </BrowserRouter>
        </Provider>
      </React.StrictMode>
    </React.Suspense>
  );
};

ReactDOM.render(<AppToRender />, document.getElementById('root'));

serviceWorker.register();
