import React, { FC } from 'react';
import styled from 'styled-components';

interface Props { 
  lat: any;
  lng: any; 
}

const Marker : FC<Props> = () => {
  return (
    <Container>
      <Pin/>
    </Container>
  );
};

export default Marker;

const Container = styled.div`
  position: absolute;  
`;

const Pin = styled.div`
  width: 25px;
  height: 25px;
  padding: 4px;
  background: yellow;
  border-radius: 50%;
  box-sizing: border-box;
  transition: 0.3s ease-in-out;
  position: relative;
  cursor: pointer;
  :after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  &:nth-child(even) {
    background: #0b210b;
  }
`;
