import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import cogoToast from 'cogo-toast';
import { AiFillEdit, AiOutlineEdit, AiOutlineLink } from 'react-icons/ai';

import { createSelector } from 'reselect';
import { Creators as LotsCreators } from 'src/store/lots';
import checkRole from 'src/utils/CheckRole';
import { RootState } from '../store';
import FileUploader from './FileUploader';

const selectIsActive = createSelector(
  (state: RootState) => state.places,
  ({ active, places }) =>
    places.find((place) => place.properties.ref === active),
);

const PlaceDetails = () => {
  const activeHidden = useSelector(
    (state: RootState) => state.places.activeHidden,
  );
  const editing = useSelector((state: RootState) => state.places.editing);
  const active = useSelector(selectIsActive);
  const { role } = useSelector((state: RootState) => state.user.data || {});
  const dispatch = useDispatch();

  const { name, video } = active?.properties || {};
  const [longitude, latitude] = active?.geometry.coordinates || [];

  const setEditing = () => {
    dispatch(LotsCreators.toggleEditing(!editing));
  };

  return (
    <Container
      open={active ? true : false}
      hide={activeHidden}
      editing={editing}
    >
      <HandleDrag
        onClick={() => dispatch(LotsCreators.toggleActive(!activeHidden))}
      >
        {activeHidden ? <BsArrowUpShort /> : <BsArrowDownShort />}
      </HandleDrag>
      {active && (
        <RelativeDiv>
          {video ? (
            <PlayerWrapper>
              <ReactPlayer
                playing
                controls
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
                width="100%"
                height="100%"
                url={video}
              />
            </PlayerWrapper>
          ) : (
            checkRole(role, ['admin', 'videomaker']) && (
              <FileUploader label="Enviar video" lot="AB" />
            )
          )}
          {checkRole(role, ['admin', 'videomaker']) && (
            <EditContainer open={editing}>
              {editing && (
                <>
                  {checkRole(role, ['admin']) && (
                    <>
                      <p>Clique no mapa para atualizar as coordenadas</p>
                      <b>latitude: {latitude}</b>
                      <br />
                      <b>longitude: {longitude}</b>
                    </>
                  )}
                </>
              )}
            </EditContainer>
          )}
          <DetailsContainer>
            <ButtonsContainer
              templateColumns={
                checkRole(role, ['admin', 'videomaker'])
                  ? '1fr 1fr 1fr 1fr 1fr 1fr'
                  : '1fr 1fr 1fr 1fr'
              }
            >
              <Title>{name}</Title>
              <CopyToClipboard
                text={window.location.href}
                onCopy={() => cogoToast.info('Link copiado')}
              >
                <IconButton>
                  <AiOutlineLink />
                </IconButton>
              </CopyToClipboard>
              {checkRole(role, ['admin', 'videomaker']) && (
                <IconButton onClick={setEditing}>
                  {editing ? <AiFillEdit /> : <AiOutlineEdit />}
                </IconButton>
              )}
            </ButtonsContainer>
            <Description></Description>
          </DetailsContainer>
        </RelativeDiv>
      )}
    </Container>
  );
};

export default PlaceDetails;

const Container = styled.div<{
  open: boolean;
  editing: boolean;
  hide: boolean;
}>`
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 460px;
  position: fixed;
  ${(props) => props.editing && 'height: auto'};
  max-width: 100%;
  transition: 0.5s;
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, 0.4);
  transform: translateY(
    ${(props) =>
      props.open ? (props.hide ? 'calc(100% - 45px)' : 0) : '100vw'}
  );
`;

const Title = styled.h1`
  font-size: 28px;
`;

const RelativeDiv = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DetailsContainer = styled.div`
  padding: 15px 15px 5px;
`;

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 57%;
  background: #000;
  @media (min-width: 640px) {
    padding-top: 56%;
  }
`;

const ButtonsContainer = styled.div<{ templateColumns: string }>`
  display: grid;
  align-items: center;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (min-width: 375px) {
    grid-template-columns: ${(props) => props.templateColumns};
  }
`;

const IconButton = styled.button`
  border: none;
  font-size: 25px;
  background: none;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

const HandleDrag = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  font-size: 35px;
  cursor: pointer;
  background: #ccc;
  position: relative;
  border-radius: 5px;
  justify-content: center;
`;

const EditContainer = styled.div<{ open: boolean }>`
  display: none;
  padding: 0.75rem;
  background: #f1f1f1;
  ${(props) =>
    props.open &&
    `
    display: block;
    flex: 1;
  `}
`;

const Description = styled.p``;
