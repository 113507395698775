import { AnyAction } from "redux"
import { createActions, createReducer } from "reduxsauce"

interface ITypes {
  SET_STATUS: string;
  UPLOAD_FILE: string;
  SET_DOWNLOAD_URL: string;
}

interface ICreators {
  setStatus: (state: string, progress: number) => AnyAction;
  uploadFile: (file: File, path: string, lot:string) => AnyAction;
  setDownloadUrl: (url: string) => AnyAction;
}

const { Types, Creators } = createActions<ITypes, ICreators>({
  setStatus: ["state", "progress"], 
  uploadFile: ["file", "path", "lot"],
  setDownloadUrl: ["url"]
});

export interface IState {
  state: string;  
  progress: number;
  downloadUrl: string | null;
}

const INITIAL_STATE: IState = {
  state: "idle",
  progress: 0,
  downloadUrl: null
};

interface SetStatus{
  state: string;
  progress: number;
}

interface SetDownloadUrl{
  url: string;
}

const setStatus = (state = INITIAL_STATE, action: SetStatus) => {  
  const { state: uploadState, progress } = action
  return({
    ...state,
    state: uploadState,
    progress
  })
}

const setDownloadUrl = (state = INITIAL_STATE, action: SetDownloadUrl ) => {
  const { url } = action;
  return({
    ...state,
    state: "done",
    progress: 100,
    downloadUrl: url
  })
}

const HANDLERS = {
  [Types.SET_STATUS]: setStatus,
  [Types.SET_DOWNLOAD_URL]: setDownloadUrl
};

export { Types, Creators };

export default createReducer<IState>(INITIAL_STATE, HANDLERS);