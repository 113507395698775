import { AnyAction } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

export interface IPlace {
  properties: {
    ref: string;
    name: string;
    description: string;
    video: string;
    type: string;
  };
  type: string;
  geometry: {
    type: string;
    coordinates: number[];
  };
}

interface ITypes {
  PLACES_REQUEST: string;
  PLACES_SUCCESS: string;
  PLACES_ERROR: string;

  PLACES_CREATING:string;
  PLACES_CREATE: string;

  SET_ACTIVE: string;
  TOGGLE_ACTIVE: string;
  TOGGLE_EDITING: string;
}

interface ICreators {
  placesRequest: () => AnyAction;
  placesSuccess: (places: IPlace[]) => AnyAction;
  placesError: (error: string) => AnyAction;

  placesCreating: (lat: string, lng: string) => AnyAction;
  placesCreate: (place: any) => AnyAction;

  setActive: (ref: string | null) => AnyAction;
  toggleEditing: (value: boolean) => AnyAction;
  toggleActive: (value: boolean) => AnyAction;
}


interface SuccessAction {
  places: IPlace[];
}

interface ErrorAction {
  error: string;
}

const { Types, Creators } = createActions<ITypes, ICreators>({
  placesRequest: null,
  placesSuccess: ['places'],
  placesError: ['error'],
  
  placesCreating: ['lat', 'lng'],
  placesCreate: ['place'],

  setActive: ['ref'],
  toggleActive: ['value'],
  toggleEditing: ['value'],
});

export interface IState {
  error: string;
  loading: boolean;
  places: IPlace[];  
  creating: boolean;
  createData: any;
  active: string | null;
  activeHidden: boolean;
  editing: boolean;
}

const INITIAL_STATE: IState = {
  error: '',
  loading: true,
  places: [],
  creating: false,
  createData: {},
  active: null,
  activeHidden: false,
  editing: false,
};
const placesRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};

const placesSuccess = (state = INITIAL_STATE, action: SuccessAction) => {
  const { places } = action;
  return {
    ...state,
    places,
    creating: false,
    createData: {},
    loading: false,
  };
};

const failure = (state = INITIAL_STATE, action: ErrorAction) => {
  const { error } = action;
  return {
    ...state,
    error,
    loading: false,
  };
};

const placesCreating = (state = INITIAL_STATE, action: {lat:string, lng: string }) => {
  const {lat, lng } = action
  return{
    ...state,
    createData: {lat, lng},
    creating: !state.creating
  }
}

interface SetActiveAction {
  ref: string;
}

const setActive = (state = INITIAL_STATE, action: SetActiveAction) => {
  const { ref } = action;
  return {
    ...state,
    active: ref,
  };
};

const toggleActive = (state = INITIAL_STATE, action: { value: boolean }) => {
  const { value } = action;
  return {
    ...state,
    activeHidden: value,
  };
};

const toggleEditing = (state = INITIAL_STATE, action: { value: boolean }) => {
  const { value } = action;
  return {
    ...state,
    editing: value,
  };
};

const HANDLERS = {
  [Types.PLACES_ERROR]: failure,  
  [Types.SET_ACTIVE]: setActive,
  [Types.TOGGLE_ACTIVE]: toggleActive,
  [Types.PLACES_REQUEST]: placesRequest,
  [Types.PLACES_SUCCESS]: placesSuccess,
  [Types.TOGGLE_EDITING]: toggleEditing,
  [Types.PLACES_CREATING]: placesCreating,
};

export { Types, Creators };

export default createReducer<IState>(INITIAL_STATE, HANDLERS);
