import firebase from 'firebase';
import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { identity, pickBy } from 'lodash';
import { subdomain } from 'src/config/domain';
import isPremium from 'src/utils/PremiumLots';
import { RootState } from '..';
import { rsf } from '../../config/firebase';
import { Creators, ILot, IState, Types } from './index';

export default function* leadsRoot() {
  yield all([
    takeLatest(Types.LOTS_REQUEST, syncLots),
    takeEvery(Types.SAVE_LOTS, saveLots),
    takeEvery(Types.UPDATE_STATUS, updateStatus),
    takeLatest(Types.UPDATE_LOT_DATABASE, updateLotDatabase),
    takeLatest(Types.UPDATE_CONDOMINIUM, updateCondominium),
  ]);
}

export function* updateLotDatabase(action: any) {
  const { ref, data } = action;
  yield call(
    rsf.firestore.setDocument,
    `tenants/${subdomain}/lots/${ref}`,
    data,
    { merge: true },
  );
}

export const lotsSerialize = (doc: firebase.firestore.DocumentSnapshot) => ({
  properties: getLotData(doc),
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: getCoordinates(doc.data()),
  },
});

const getCoordinates = (data: any) => {
  const { longitude, latitude } = data;
  return longitude && latitude
    ? [parseFloat(longitude), parseFloat(latitude)]
    : [-48.425202683266434, -23.15937550746007];
};

const getLotData = (doc: firebase.firestore.DocumentSnapshot) => {
  const {
    rm,
    iptu,
    lote,
    valor,
    tamanho,
    parcela,
    entrada,
    videoUrl,
    status,
    condominio,
  } = doc.data() || {};
  return {
    cluster: false,
    ref: doc.id,
    rm,
    iptu,
    lote,
    valor,
    tamanho,
    parcela,
    entrada,
    videoUrl,
    status,
    condominio,
    custo: (parseInt(valor) * 1000) / parseInt(tamanho),
    favorited: false,
  };
};

export function* syncLots() {
  const type: any = 'collection';
  const channel = rsf.firestore.channel(`tenants/${subdomain}/lots`, type);

  while (true) {
    const user = yield select((state: RootState) => state.user.data);
    const { favorites } = user || {};
    const snapshot: firebase.firestore.QuerySnapshot = yield take(channel);
    const lots = snapshot.docs.map(lotsSerialize);
    yield put(Creators.lotsSuccess(lots));
    if (favorites) yield put(Creators.fetchedFavorites(favorites));
  }
}

export function* saveLots(action: any) {
  const stateLots: ILot[] = yield select((state: RootState) => state.lots.lots);
  const filteredByBlockName = stateLots.filter(
    (lot) =>
      !lot.properties.lote.includes('KV') &&
      !lot.properties.lote.includes('KT') &&
      !lot.properties.lote.includes('KS') &&
      !lot.properties.lote.includes('KW'),
  );
  const stateLotsAvailable = filteredByBlockName.filter(
    (lot: ILot) => lot.properties.status === 'available',
  );

  for (let i = 0; i < stateLotsAvailable.length; i++) {
    const lot = stateLotsAvailable[i];
    const percentage = parseInt(
      ((100 * i) / stateLotsAvailable.length).toFixed(2),
    );
    yield delay(300);
    yield fork(
      saveLot,
      pickBy({ ...lot.properties, status: 'sold' }, identity),
    );
    yield put(Creators.updateProgress(percentage));
  }

  const { lots } = action;
  for (let i = 0; i < lots.length; i++) {
    const lot = lots[i];
    const percentage = parseInt(((100 * i) / lots.length).toFixed(2));
    yield delay(300);
    yield fork(saveLot, { ...lot, status: 'available' });
    yield put(Creators.updateProgress(percentage));
  }
  yield put(Creators.updateProgress(100));
  yield call(setUpdateAt);
}

export function* setUpdateAt() {
  yield call(
    rsf.firestore.setDocument,
    `tenants/${subdomain}`,
    { updatedAt: new Date() },
    {
      merge: true,
    },
  );
}

export function* saveLot(lot: any) {
  const ref = lot.lote.split('/')[0];
  yield call(
    rsf.firestore.setDocument,
    `tenants/${subdomain}/lots/${ref}`,
    { ...lot },
    {
      merge: true,
    },
  );
  yield call(setUpdateAt);
}

export function* updateStatus(action: any) {
  const { ref, status } = action;
  yield call(
    rsf.firestore.setDocument,
    `tenants/${subdomain}/lots/${ref}`,
    { status },
    { merge: true },
  );
  yield call(setUpdateAt);
}

export function* updateCondominium(action: any) {
  const { value } = action;
  const { type, condominio } = value;
  const { lots } = yield select((state: IState) => state.lots);
  let lotsToUpdate = [];
  if (type === 'premium') {
    lotsToUpdate = lots.filter((lot: ILot) => isPremium(lot.properties.ref));
  } else {
    lotsToUpdate = lots.filter((lot: ILot) => !isPremium(lot.properties.ref));
  }
  for (let i = 0; i < lotsToUpdate.length; i++) {
    const lot: ILot = lotsToUpdate[i];
    const percentage = parseInt(((100 * i) / lotsToUpdate.length).toFixed(2));
    yield delay(300);
    yield fork(saveLot, { lote: lot?.properties?.ref, condominio });
    yield put(Creators.updateProgress(percentage));
  }
  yield put(Creators.updateProgress(100));
}
