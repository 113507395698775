import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({ className, ...props }) => {
  const classes = classNames('brand', className);

  return (
    <div
      {...props}
      className={classes}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Link to="/">
        <Image
          src={require('./../../../assets/images/logo.png')}
          alt="Open"
          width={44}
          height={44}
        />
      </Link>
      <h1 className="m-0" style={{ fontSize: 32, marginLeft: 15 }}>
        LoteMap
      </h1>
    </div>
  );
};

export default Logo;
