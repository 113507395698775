import cogoToast from 'cogo-toast';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
  AiFillEdit,
  AiFillHeart,
  AiOutlineEdit,
  AiOutlineHeart,
  AiOutlineLink,
} from 'react-icons/ai';
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs';
import { FiVideoOff } from 'react-icons/fi';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { createSelector } from 'reselect';
import { Creators as LotsCreators } from 'src/store/lots';
import { Creators } from 'src/store/user';
import checkRole from 'src/utils/CheckRole';
import { RootState } from '../store';
import DeleteVideo from './DeleteVideo';
import FileUploader from './FileUploader';

import calcPrice from 'src/utils/CalcPrice';
import isPremium from 'src/utils/PremiumLots';

const selectIsActive = createSelector(
  (state: RootState) => state.lots,
  ({ active, lots }) => lots.find((lot) => lot.properties.ref === active),
);

const Details = () => {
  const activeHidden = useSelector(
    (state: RootState) => state.lots.activeHidden,
  );
  const editing = useSelector((state: RootState) => state.lots.editing);
  const active = useSelector(selectIsActive);
  const { role } = useSelector((state: RootState) => state.user.data || {});
  const dispatch = useDispatch();

  const { ref, lote, valor, tamanho, parcela, videoUrl, favorited } =
    active?.properties || {};
  const [longitude, latitude] = active?.geometry.coordinates || [];

  const handleFavorite = () => {
    lote &&
      dispatch(
        !favorited ? Creators.addFavorite(lote) : Creators.removeFavorite(lote),
      );
  };

  const setEditing = () => {
    dispatch(LotsCreators.toggleEditing(!editing));
  };

  const handleDelete = () => {
    if (ref) {
      const { hide } = cogoToast.warn(
        <DeleteVideo
          handleConfirm={() =>
            dispatch(LotsCreators.updateLotDatabase(ref, { videoUrl: null }))
          }
          lote={ref}
        />,
        {
          hideAfter: 0,
          onClick: () => hide && hide(),
        },
      );
    }
  };

  const parcelaNumber = parseFloat(parcela || '');
  const valorNumber = parseFloat(valor || '');
  const entryNumber = isPremium(ref || '') ? 0.09 : 0.05;
  const entradaNumber = parseFloat(valor || '') * entryNumber;

  if (!lote) return null;

  return (
    <Container
      open={active ? true : false}
      hide={activeHidden}
      editing={editing}
    >
      <HandleDrag
        onClick={() => dispatch(LotsCreators.toggleActive(!activeHidden))}
      >
        {activeHidden ? <BsArrowUpShort /> : <BsArrowDownShort />}
      </HandleDrag>
      {active && (
        <RelativeDiv>
          {videoUrl ? (
            <PlayerWrapper>
              <ReactPlayer
                playing
                controls
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
                width="100%"
                height="100%"
                url={videoUrl}
              />
            </PlayerWrapper>
          ) : (
            checkRole(role, ['admin', 'videomaker']) && (
              <FileUploader label="Enviar video" lot={lote} />
            )
          )}
          {checkRole(role, ['admin', 'videomaker']) && (
            <EditContainer open={editing}>
              {editing && (
                <>
                  {checkRole(role, ['admin']) && (
                    <>
                      <p>Clique no mapa para atualizar as coordenadas</p>
                      <b>latitude: {latitude}</b>
                      <br />
                      <b>longitude: {longitude}</b>
                    </>
                  )}
                </>
              )}
            </EditContainer>
          )}
          <DetailsContainer>
            <ButtonsContainer
              templateColumns={
                checkRole(role, ['admin', 'videomaker'])
                  ? '1fr 1fr 1fr 1fr 1fr 1fr'
                  : '1fr 1fr 1fr 1fr'
              }
            >
              <Title>{lote}</Title>
              <CopyToClipboard
                text={window.location.href}
                onCopy={() => cogoToast.info('Link copiado')}
              >
                <IconButton>
                  <AiOutlineLink />
                </IconButton>
              </CopyToClipboard>
              <IconButton onClick={handleFavorite}>
                {favorited ? (
                  <AiFillHeart style={{ color: 'red' }} />
                ) : (
                  <AiOutlineHeart />
                )}
              </IconButton>
              {checkRole(role, ['admin']) && (
                <IconButton onClick={setEditing}>
                  {editing ? <AiFillEdit /> : <AiOutlineEdit />}
                </IconButton>
              )}
              {checkRole(role, ['admin']) && (
                <IconButton>
                  <FiVideoOff onClick={handleDelete} />
                </IconButton>
              )}
            </ButtonsContainer>
            <DetailItems>
              <DetailItem>
                <DetailTitle>Tamanho</DetailTitle>
                <DetailValue>{tamanho && parseInt(tamanho)} m²</DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailTitle>Entrada</DetailTitle>
                <DetailValue>
                  {entradaNumber.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailTitle>Mensal</DetailTitle>
                <DetailValue>
                  {(
                    calcPrice(valorNumber - entradaNumber, 120, 0.166919) + 0
                  ).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </DetailValue>
              </DetailItem>
              {role && (
                <>
                  {/* <DetailItem>
                    <DetailTitle>Condomínio</DetailTitle>
                    <DetailValue>
                      {condominioNumber.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </DetailValue>
                  </DetailItem> */}
                  <DetailItem>
                    <DetailTitle>Total</DetailTitle>
                    <DetailValue>
                      {(entradaNumber + 120 * parcelaNumber).toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        },
                      )}
                    </DetailValue>
                  </DetailItem>

                  <DetailItem>
                    <DetailTitle>Á vista</DetailTitle>
                    <DetailValue>
                      {(valorNumber * 0.93).toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </DetailValue>
                  </DetailItem>
                </>
              )}
            </DetailItems>
          </DetailsContainer>
          {!role && (
            <DetailsList>
              <li>* 50% desconto parcelas restantes ao construir</li>
              <li>* Título 14 clubes resorts sem custo adicional</li>
            </DetailsList>
          )}
        </RelativeDiv>
      )}
    </Container>
  );
};

export default Details;

const Container = styled.div<{
  open: boolean;
  editing: boolean;
  hide: boolean;
}>`
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 460px;
  position: fixed;
  ${(props) => props.editing && 'height: auto'};
  max-width: 100%;
  transition: 0.5s;
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, 0.4);
  transform: translateY(
    ${(props) =>
      props.open ? (props.hide ? 'calc(100% - 45px)' : 0) : '100vw'}
  );
`;

const Title = styled.h1`
  font-size: 28px;
`;

const RelativeDiv = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DetailsContainer = styled.div`
  padding: 15px 15px 5px;
`;

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 57%;
  background: #000;
  @media (min-width: 640px) {
    padding-top: 56%;
  }
`;

const DetailItems = styled.div`
  padding: 15px 0;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
`;

const DetailItem = styled.div``;

const DetailTitle = styled.p`
  font-weight: bold;
  font-size: 13px;
`;

const DetailValue = styled.p`
  font-size: 14px;
`;

const ButtonsContainer = styled.div<{ templateColumns: string }>`
  display: grid;
  align-items: center;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (min-width: 375px) {
    grid-template-columns: ${(props) => props.templateColumns};
  }
`;

const IconButton = styled.button`
  border: none;
  font-size: 25px;
  background: none;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

const HandleDrag = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  font-size: 35px;
  cursor: pointer;
  background: #ccc;
  position: relative;
  border-radius: 5px;
  justify-content: center;
`;

const EditContainer = styled.div<{ open: boolean }>`
  display: none;
  padding: 0.75rem;
  background: #f1f1f1;
  ${(props) =>
    props.open &&
    `
    display: block;
    flex: 1;
  `}
`;

const DetailsList = styled.ul`
  padding: 0 15px 10px;
  list-style: none;
  li {
    font-size: 13px;
    margin: 5px 0;
  }
`;
