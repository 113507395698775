import React from 'react';
import styled from 'styled-components';
import { Portal } from 'react-portal';

const defaultItems = [
  { label: 'Lote' },
  { label: 'm²', props: { small: true } },
  { label: 'Entrada' },
  { label: 'Mensal' },
];

const TableHeader = ({ open, items = defaultItems }: any) => {
  return (
    <Portal>
      <Container open={open}>
        {items.map((item: any) => (
          <ItemText key={item.label} {...item.props}>
            {item.label}
          </ItemText>
        ))}
      </Container>
    </Portal>
  );
};

const Container = styled.div<{ open: boolean }>`
  top: 52px;
  position: fixed;
  background: #fff;
  height: 40px;
  z-index: 9999;
  width: 540px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  transition: 0.5s;
  align-items: center;
  transform: translateX(${(props) => (props.open ? 0 : '-100vw')});
`;

const ItemText = styled.p<{ small?: boolean }>`
  flex: ${(props) => (props.small ? 1 : 2)};
  text-align: center;
  font-weight: 700;
`;

export default TableHeader;
