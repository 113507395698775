export default function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * toFixed(Math.abs(num) / 1000, 1) + 'k'
    : Math.sign(num) * Math.abs(num);
}

function toFixed(num, fixed) {
  var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}
