import React from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { CgUnavailable } from 'react-icons/cg';
import { MdCloudDone } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { Creators } from 'src/store/upload';
import styled from 'styled-components';
const Fade = require('react-reveal/Fade');

type Props = {
  label: string;
  lot: string;
  small?: boolean;
};

const FileUploader = ({ label, lot, small = false }: Props) => {
  const { state, progress } = useSelector((state: RootState) => state.upload);
  const role = useSelector((state: RootState) => state.user.data?.role);
  const dispatch = useDispatch();
  const handleChange = (file: File) => {
    dispatch(Creators.uploadFile(file, `/videos/${file.name}`, lot));
  };

  return (
    <Container small={small}>
      {state === 'running' ? (
        <ProgressContainer>
          <ProgressBar progress={progress} />
        </ProgressContainer>
      ) : state === 'done' ? (
        <DoneContainer>
          <Fade bottom>
            <MdCloudDone />
            <DoneText>100%</DoneText>
          </Fade>
        </DoneContainer>
      ) : (
        <>
          <InputLabel htmlFor="file-input" border={!small}>
            {!small && (
              <>
                {role === 'admin' || role === 'videomaker' ? (
                  <AiOutlineCloudUpload />
                ) : (
                  <CgUnavailable />
                )}
              </>
            )}
            <LabelText>{label}</LabelText>
          </InputLabel>
          <InputFile
            type="file"
            id="file-input"
            multiple={false}
            onChange={(e) =>
              e.target.files?.[0] && handleChange(e.target.files[0])
            }
          />
        </>
      )}
    </Container>
  );
};

export default FileUploader;

const Container = styled.div<{ small: boolean }>`
  width: 100%;
  display: flex;
  padding: ${(props) => (props.small ? '8px' : '20px')};
  min-height: ${(props) => (props.small ? 'auto' : '200px')};
  background: ${(props) => (props.small ? '#FFF' : '#e4e4e4')};
  border-bottom: ${(props) => (props.small ? '1px solid #999' : 'none')};
`;

const InputFile = styled.input`
  display: none;
`;

const InputLabel = styled.label<{ border: boolean }>`
  flex: 1;
  display: flex;
  font-size: 54px;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: ${(props) => (props.border ? '2px dashed #999' : 'none')};
  ${(props) =>
    !props.border &&
    `
    padding: 8px;
    border: 2px solid #000;
    border-radius: 8px;
  `}
  :hover {
    background: #ccc;
    cursor: pointer;
  }
`;

const LabelText = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

const ProgressContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  font-size: 54px;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const ProgressBar = styled.div<{ progress: number }>`
  width: 100%;
  height: 5px;
  position: relative;
  background: #ddd;
  border-radius: 8px;
  ::after {
    content: ' ';
    top: 0;
    left: 0;
    height: 5px;
    width: 100%;
    background: green;
    position: absolute;
    border-radius: 8px;
    transition: 0.5s;
    ${(props) => `transform: translateX(calc(${props.progress}% - 100%))`}
  }
`;

const DoneContainer = styled.div`
  width: 100%;
  flex: 1;
  color: green;
  display: flex;
  font-size: 54px;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const DoneText = styled.p`
  font-size: 18px;
  text-align: center;
  font-weight: bold;
`;
