import { AnyAction } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import * as immutable from 'object-path-immutable';
export interface ILot {
  properties: {
    rm: string;
    ref: string;
    iptu: string;
    lote: string;
    valor: string;
    tamanho: string;
    parcela: string;
    entrada: string;
    custo: number;
    cluster: boolean;
    videoUrl: string;
    favorited: boolean;
    condominio: string;
    status: string;
  };
  type: string;
  geometry: {
    type: string;
    coordinates: number[];
  };
}

interface ITypes {
  LOTS_REQUEST: string;
  LOTS_SUCCESS: string;
  LOTS_ERROR: string;

  SAVE_LOTS: string;
  UPDATE_PROGRESS: string;
  FIND_ALL_COORDINATES: string;
  SET_ACTIVE: string;
  SET_HOVERED: string;
  TOGGLE_EDITING: string;
  TOGGLE_ACTIVE: string;
  UPLOAD_VIDEO: string;
  UPDATE_LOT: string;
  UPDATE_LOT_DATABASE: string;
  UPDATE_STATUS: string;

  UPDATE_CONDOMINIUM: string;

  FETCHED_FAVORITES: string;
}

interface ICreators {
  lotsRequest: () => AnyAction;
  lotsSuccess: (lots: ILot[]) => AnyAction;
  lotsError: (error: string) => AnyAction;

  saveLots: (lot: ILot[]) => AnyAction;
  updateProgress: (progress: number) => AnyAction;
  findAllCoordinates: () => AnyAction;
  setActive: (ref: string | null) => AnyAction;
  toggleEditing: (value: boolean) => AnyAction;
  toggleActive: (value: boolean) => AnyAction;
  setHovered: (ref: string) => AnyAction;
  uploadVideo: (video: File) => AnyAction;
  updateLot: (ref: string, data: any) => AnyAction;
  updateLotDatabase: (ref: string, data: any) => AnyAction;
  updateStatus: (ref: string, status: string) => AnyAction;

  updateCondominium: (value: object) => AnyAction;

  fetchedFavorites: (favorites: string[]) => AnyAction;
}

interface SuccessAction {
  lots: ILot[];
}

interface ErrorAction {
  error: string;
}

interface SetActiveAction {
  ref: string;
}

interface SetHoveredAction {
  ref: string;
}

const { Types, Creators } = createActions<ITypes, ICreators>({
  lotsRequest: null,
  lotsSuccess: ['lots'],
  lotsError: ['error'],
  saveLots: ['lots'],
  updateProgress: ['progress'],
  findAllCoordinates: null,
  setActive: ['ref'],
  toggleActive: ['value'],
  toggleEditing: ['value'],
  setHovered: ['ref'],
  uploadVideo: ['video'],
  updateLot: ['ref', 'data'],
  updateLotDatabase: ['ref', 'data'],
  updateCondominium: ['value'],
  updateStatus: ['ref', 'status'],
  fetchedFavorites: ['favorites'],
});

export interface IState {
  error: string;
  loading: boolean;
  lots: ILot[];
  active: string | null;
  activeHidden: boolean;
  editing: boolean;
  hovered: string | null;
  updating: boolean;
  progress: number;
}

const INITIAL_STATE: IState = {
  error: '',
  loading: true,
  lots: [],
  active: null,
  activeHidden: false,
  editing: false,
  hovered: null,
  updating: false,
  progress: 0,
};

const lotsRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};

const lotsSuccess = (state = INITIAL_STATE, action: SuccessAction) => {
  const { lots } = action;
  return {
    ...state,
    lots,
    loading: false,
  };
};

const setActive = (state = INITIAL_STATE, action: SetActiveAction) => {
  const { ref } = action;
  return {
    ...state,
    active: ref,
  };
};

const setHovered = (state = INITIAL_STATE, action: SetHoveredAction) => {
  const { ref } = action;
  return {
    ...state,
    hovered: ref,
  };
};

const toggleActive = (state = INITIAL_STATE, action: { value: boolean }) => {
  const { value } = action;
  return {
    ...state,
    activeHidden: value,
  };
};

const toggleEditing = (state = INITIAL_STATE, action: { value: boolean }) => {
  const { value } = action;
  return {
    ...state,
    editing: value,
  };
};

const fetchedFavorites = (
  state = INITIAL_STATE,
  action: { favorites: string[] },
) => {
  const { favorites } = action || { favorites: [] };
  let lots = [...state.lots];

  lots.forEach((lot, index) => {
    const isFavorited = favorites.includes(lot.properties.lote);
    lots = immutable.set(lots, [index, 'properties', 'favorited'], isFavorited);
  });

  return {
    ...state,
    lots,
  };
};

const updateLot = (
  state = INITIAL_STATE,
  action: { ref: string; data: any },
) => {
  const { ref, data } = action;
  const updateLotIndex = state.lots.findIndex(
    (lot) => lot.properties.ref === ref,
  );
  const lots: any = immutable.merge(state.lots, [updateLotIndex], data);
  return {
    ...state,
    lots,
  };
};

const failure = (state = INITIAL_STATE, action: ErrorAction) => {
  const { error } = action;
  return {
    ...state,
    error,
    loading: false,
  };
};

const updating = (state = INITIAL_STATE) => {
  return {
    ...state,
    updating: true,
  };
};

const updateProgress = (
  state = INITIAL_STATE,
  action: { progress: number },
) => {
  const { progress } = action;
  return {
    ...state,
    progress,
    updating: progress === 100 ? false : true,
  };
};

const HANDLERS = {
  [Types.LOTS_REQUEST]: lotsRequest,
  [Types.LOTS_SUCCESS]: lotsSuccess,
  [Types.SET_ACTIVE]: setActive,
  [Types.SET_HOVERED]: setHovered,
  [Types.TOGGLE_ACTIVE]: toggleActive,
  [Types.TOGGLE_EDITING]: toggleEditing,
  [Types.UPDATE_LOT]: updateLot,
  [Types.LOTS_ERROR]: failure,
  [Types.FETCHED_FAVORITES]: fetchedFavorites,
  [Types.SAVE_LOTS]: updating,
  [Types.UPDATE_PROGRESS]: updateProgress,
};

export { Types, Creators };

export default createReducer<IState>(INITIAL_STATE, HANDLERS);
