import { eventChannel } from 'redux-saga';
import {
  delay,
  all,
  call,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { subdomain } from 'src/config/domain';
import { rsf } from 'src/config/firebase';
import { Creators, Types } from '.';

export default function* uploadRoot() {
  yield all([takeLatest(Types.UPLOAD_FILE, uploadFile)]);
}

export function* uploadFile(action: any) {
  const { path, file, lot } = action;
  const task = rsf.storage.uploadFile(path, file);
  const channel = eventChannel((emit) => task.on('state_changed', emit));
  yield takeEvery(channel, updateStatus);
  yield task;
  const url = yield call(rsf.storage.getDownloadURL, action.path);
  yield put(Creators.setDownloadUrl(url));
  yield delay(1000);
  const ref = lot.split('/')[0];
  yield call(
    rsf.firestore.setDocument,
    `tenants/${subdomain}/lots/${ref}`,
    { videoUrl: url },
    { merge: true },
  );
  yield put(Creators.setStatus('idle', 0));
}

export function* updateStatus(snapshot: any) {
  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  const state = snapshot.state;
  yield put(Creators.setStatus(state, progress));
}
