import { all, call, put, takeLatest, take, select } from 'redux-saga/effects';
import { rsf } from 'src/config/firebase';
import firebase from 'firebase';
import { Creators, Types } from '.';
import { RootState } from '..';
import { Creators as LotsCreators } from '../lots';

export default function* userRoot() {
  yield all([
    takeLatest(Types.LOGIN_REQUEST, loginRequest),
    takeLatest(Types.WHATCH_USER, syncUserSaga),
    takeLatest(Types.ADD_FAVORITE, addFavorite),
    takeLatest(Types.REMOVE_FAVORITE, removeFavorite),
    takeLatest(Types.LOGOUT_REQUEST, signOutSaga),
  ]);
}

const authProvider = new firebase.auth.GoogleAuthProvider();

export function* syncUserSaga() {
  const channel = yield call(rsf.auth.channel);

  while (true) {
    const { error, user } = yield take(channel);

    if (user) {
      yield getUserData(user);
    } else yield put(Creators.loginFailed(error));
  }
}

export function* getUserData(user: any) {
  const type: any = 'document';
  const channel = rsf.firestore.channel(`users/${user.uid}`, type);

  while (true) {
    const snapshot: firebase.firestore.DocumentSnapshot = yield take(channel);
    const favorites = snapshot?.data()?.favorites || [];
    const data: any = snapshot?.data() || null;
    if (!data) yield call(setUser, user);
    yield put(LotsCreators.fetchedFavorites(favorites));
    yield put(Creators.syncUser({ ...user, ...data }));
  }
}

export function* loginRequest() {
  try {
    yield call(rsf.auth.signInWithRedirect, authProvider);
    yield put(Creators.loginSuccess());
  } catch (error) {
    console.log(error);
    yield put(Creators.loginFailed(error));
  }
}

export function* addFavorite(action: any) {
  const logged = yield select((state: RootState) => state.user.logged);
  if (!logged) {
    yield call(loginRequest);
  } else {
    const { uid } = yield select((state: RootState) => state.user.data);
    const { lot } = action;
    yield call(
      rsf.firestore.setDocument,
      `users/${uid}`,
      {
        favorites: firebase.firestore.FieldValue.arrayUnion(lot),
      },
      {
        merge: true,
      },
    );
  }
}

export function* removeFavorite(action: any) {
  const { uid } = yield select((state: RootState) => state.user.data);
  const { lot } = action;
  yield call(
    rsf.firestore.setDocument,
    `users/${uid}`,
    {
      favorites: firebase.firestore.FieldValue.arrayRemove(lot),
    },
    {
      merge: true,
    },
  );
}

export function* setUser(user: any) {
  yield call(
    rsf.firestore.setDocument,
    `users/${user.uid}`,
    extractUserData(user),
    {
      merge: true,
    },
  );
}

const extractUserData = (user: any) => {
  console.log(user);
  return {
    displayName: user.displayName,
    email: user.email,
  };
};

function* signOutSaga() {
  try {
    yield call(rsf.auth.signOut);
    yield put(Creators.logoutSuccess());
  } catch (error) {
    console.log(error);
  }
}
