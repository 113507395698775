import { combineReducers } from 'redux';

import lots from './lots';
import upload from './upload';
import user from './user';
import tenant from './tenant';
import places from './places';

const rootReducers = combineReducers({
  lots,
  upload,
  user,
  tenant,
  places
});

export type RootState = ReturnType<typeof rootReducers>;

export default rootReducers;
