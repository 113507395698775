import GoogleMapReact from 'google-map-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSupercluster from 'use-supercluster';

import { createSelector } from 'reselect';
import { Creators } from 'src/store/lots';
import { Creators as PlacesCreators } from 'src/store/places';
import checkRole from 'src/utils/CheckRole';
import useWindowDimensions from '../hooks/useWindowSizes';
import { RootState } from '../store';
import Marker from './Marker';
import MarkerPosition from './MarkerPosition';
import PlaceMarker from './PlaceMarker';

const MarkerCluster = ({ children }: any) => children;

const selectIsHovered = createSelector(
  (state: RootState) => state.lots,
  ({ hovered, lots }) => lots.find((lot) => lot.properties.ref === hovered),
);

const selectIsActive = createSelector(
  (state: RootState) => state.lots,
  ({ active, lots }) => lots.find((lot) => lot.properties.ref === active),
);

const GoogleMap = () => {
  const [position, setPosition] = useState<null | number[]>(null);
  const hovered = useSelector(selectIsHovered);
  const active = useSelector(selectIsActive);
  const lots = useSelector((state: RootState) => state.lots.lots);
  const places = useSelector((state: RootState) => state.places.places);
  const { role } = useSelector((state: RootState) => state.user.data || {});
  const activeHidden = useSelector(
    (state: RootState) => state.lots.activeHidden,
  );
  const editing = useSelector((state: RootState) => state.lots.editing);
  const dispatch = useDispatch();
  const [bounds, setBounds] = useState<any>(null);
  const [zoom, setZoom] = useState(10);

  const { width } = useWindowDimensions();

  const lotsWithCoordinates = lots.filter(
    (lot) =>
      !!lot.geometry.coordinates[0] &&
      lot.properties.status !== 'sold' &&
      lot.properties.status !== 'reserved',
  );

  const mapRef: any = useRef(null);

  useEffect(() => {
    navigator.geolocation.watchPosition(function (position) {
      setPosition([position.coords.latitude, position.coords.longitude]);
    });
  }, []);

  useEffect(() => {
    if (active && mapRef) {
      const [longitude, latitude] = active.geometry.coordinates;
      mapRef?.current?.setZoom(19);
      mapRef?.current?.panTo({ lat: latitude, lng: longitude });
    }
  }, [active, mapRef]);

  const config = {
    center: {
      lat: -23.15937550746007,
      lng: -48.425202683266434,
    },
    zoom: 14.5,
  };

  const { clusters, supercluster } = useSupercluster<any>({
    points: lotsWithCoordinates,
    bounds,
    zoom,
    options: { radius: 100 },
  });

  const handleMapClick = ({ lat, lng }: any) => {
    if ((!editing || !hovered) && checkRole(role, ['admin'])) {
      dispatch(PlacesCreators.placesCreating(lat, lng));
    } else if (hovered && editing) {
      dispatch(
        Creators.updateLot(hovered.properties.ref, {
          geometry: { coordinates: [lng, lat] },
        }),
      );
    }
  };

  return (
    <>
      <div
        style={{
          height:
            width > 640 ? '100vh' : active && !activeHidden ? '75vh' : '100vh',
          transition: '0.3s',
          width: '100vw',
        }}
      >
        <GoogleMapReact
          defaultZoom={config.zoom}
          defaultCenter={config.center}
          options={{
            mapTypeId: 'satellite',
            zoomControl: false,
            fullscreenControl: false,
          }}
          onChange={({ zoom, bounds }: any) => {
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]);
          }}
          onClick={handleMapClick}
          bootstrapURLKeys={{ key: 'AIzaSyCXrVw3IJrwtBVvba2JOhfqaDRezAMdhB4' }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map;
          }}
        >
          {zoom >= 16 &&
            places.map((place) => (
              <PlaceMarker
                key={`${place.properties.ref}`}
                type={place.properties.type}
                id={`${place.properties.ref}`}
                lat={place.geometry.coordinates[1]}
                lng={place.geometry.coordinates[0]}
              />
            ))}
          {position && <MarkerPosition lat={position[0]} lng={position[1]} />}
          {hovered && (
            <Marker
              id={hovered}
              featured={true}
              properties={hovered.properties}
              lat={hovered.geometry.coordinates[1]}
              lng={hovered.geometry.coordinates[0]}
            />
          )}
          {clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount,
            } = cluster.properties;

            return isCluster ? (
              <MarkerCluster
                key={`${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  className="cluster-marker"
                  style={{
                    width: `${
                      10 + (pointCount / lotsWithCoordinates.length) * 20
                    }px`,
                    height: `${
                      10 + (pointCount / lotsWithCoordinates.length) * 20
                    }px`,
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20,
                    );
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({ lat: latitude, lng: longitude });
                  }}
                >
                  {pointCount}
                </div>
              </MarkerCluster>
            ) : (
              <Marker
                key={cluster.properties.ref}
                lat={latitude}
                lng={longitude}
                properties={cluster.properties}
                id={cluster.properties.ref}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    </>
  );
};

export default GoogleMap;
