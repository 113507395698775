import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import styled from "styled-components"

const SearchForm = ({ open, handleClose, handleChange }: any) => {
  return (
    <Container open={open}>
      {open && (
        <>
          <SearchInput autoFocus placeholder="Buscar por Nome do lote" onChange={e => handleChange(e.target.value)} />
          <CloseButton onClick={handleClose}>
            <AiOutlineClose />
          </CloseButton>
        </>
      )}
    </Container>
  )
}

export default SearchForm

const Container = styled.div<{ open: boolean }>`
  left: 0;
  top: 0px;
  width: 540px;
  display: flex;
  z-index: 99999;
  color: #fff;
  position: fixed;
  padding: 5px;
  max-width: 100%;
  transition: 0.5s;
  align-items: center;
  background-color: white;
  justify-content: space-between;  
  transform: translateX(${props => props.open ? 0 : '-100vw'});
`

const SearchInput = styled.input`
  flex: 1;
  padding: 12px;
  border: none;
  background: #fff;    
  box-shadow: 1px 2px 8px 0 rgba(0,0,0,.4);
  border-radius: 8px;  
  :focus{
    outline: none;
  }
  ::placeholder{
    color: #999;
  }
`

const CloseButton = styled.button`
  color: #000;
  right: 15px;
  z-index: 9999;
  padding: 4px 6px;
  position: absolute;
  border: none;
  background: none;
  border-radius: 50%;
  cursor: pointer;  
`