import { AnyAction } from 'redux';
import { createActions, createReducer } from 'reduxsauce';
import firebase from 'firebase';

export interface ITenant {
  name: string;
  logo: string;
  updatedAt: firebase.firestore.Timestamp;
}

interface ITypes {
  TENANT_REQUEST: string;
  TENANT_SUCCESS: string;
  TENANT_ERROR: string;
}

interface ICreators {
  tenantRequest: (ref: string) => AnyAction;
  tenantSuccess: (tenant: ITenant) => AnyAction;
  tenantError: (error: string) => AnyAction;
}

const { Types, Creators } = createActions<ITypes, ICreators>({
  tenantRequest: ['ref'],
  tenantSuccess: ['tenant'],
  tenantError: ['error'],
});

export interface IState {
  error: string;
  loading: boolean;
  data: ITenant | null;
}

const INITIAL_STATE: IState = {
  error: '',
  loading: true,
  data: null,
};

const tenantRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};

const tenantSuccess = (state = INITIAL_STATE, action: { tenant: any }) => {
  const { tenant } = action;
  return {
    ...state,
    data: tenant.data(),
    loading: false,
  };
};

const failure = (state = INITIAL_STATE, action: { error: string }) => {
  const { error } = action;
  return {
    ...state,
    error,
    loading: false,
  };
};

const HANDLERS = {
  [Types.TENANT_REQUEST]: tenantRequest,
  [Types.TENANT_SUCCESS]: tenantSuccess,
  [Types.TENANT_ERROR]: failure,
};

export { Types, Creators };

export default createReducer<IState>(INITIAL_STATE, HANDLERS);
