import { all, fork, takeLatest } from 'redux-saga/effects';
import { rsf } from 'src/config/firebase';
import { Creators, Types } from '.';

export default function* tenantRoot() {
  yield all([takeLatest(Types.TENANT_REQUEST, syncTenant)]);
}

export function* syncTenant(action: any) {
  const { ref } = action;
  yield fork(rsf.firestore.syncDocument, `tenants/${ref}`, {
    successActionCreator: Creators.tenantSuccess,
  });
}
