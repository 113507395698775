import { AnyAction } from 'redux';
import { createActions, createReducer } from 'reduxsauce';

interface IUser {
  name: string;
  photoURL: string;
  role: string;
  displayName?: string;
}

interface ITypes {
  LOGIN_REQUEST: string;
  LOGIN_SUCCESS: string;
  LOGIN_FAILED: string;

  LOGOUT_REQUEST: string;
  LOGOUT_SUCCESS: string;
  LOGOUT_FAILED: string;

  WHATCH_USER: string;
  SYNC_USER: string;

  ADD_FAVORITE: string;
  REMOVE_FAVORITE: string;
}

interface ICreators {
  loginRequest: () => AnyAction;
  loginSuccess: () => AnyAction;
  loginFailed: (error: any) => AnyAction;

  logoutRequest: () => AnyAction;
  logoutSuccess: () => AnyAction;
  logoutFailed: (error: any) => AnyAction;

  whatchUser: () => AnyAction;
  syncUser: (user: IUser) => AnyAction;

  addFavorite: (lot: string) => AnyAction;
  removeFavorite: (lot: string) => AnyAction;
}

const { Types, Creators } = createActions<ITypes, ICreators>({
  loginRequest: null,
  loginSuccess: null,
  loginFailed: ['error'],

  logoutRequest: null,
  logoutSuccess: null,
  logoutFailed: ['error'],

  whatchUser: null,
  syncUser: ['user'],
  addFavorite: ['lot'],
  removeFavorite: ['lot'],
});

export interface IState {
  logged: boolean;
  logging: boolean;
  data: IUser;
  error: any;
}

const INITIAL_STATE: IState = {
  logged: false,
  logging: true,
  data: {
    name: '',
    photoURL: '',
    role: '',
  },
  error: null,
};

type LoginFailed = { error: any };

const loginRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    logging: true,
  };
};

const loginSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    logging: false,
    logged: true,
  };
};

const logoutSuccess = () => {
  return { ...INITIAL_STATE, logging: false };
};

const syncUser = (state = INITIAL_STATE, action: { user: IUser }) => {
  const { user } = action;
  return {
    ...state,
    logging: false,
    logged: true,
    data: { ...state.data, ...user },
  };
};

const loginFailed = (state = INITIAL_STATE, action: LoginFailed) => {
  const { error } = action;
  return {
    ...state,
    logging: false,
    logged: false,
    data: INITIAL_STATE.data,
    error,
  };
};

const HANDLERS = {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILED]: loginFailed,
  [Types.SYNC_USER]: syncUser,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
};

export { Creators, Types };

export default createReducer<IState>(INITIAL_STATE, HANDLERS);
