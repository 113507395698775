import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import ReduxSagaFirebase from 'redux-saga-firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyCXrVw3IJrwtBVvba2JOhfqaDRezAMdhB4',
  authDomain: 'nv2-maps.firebaseapp.com',
  databaseURL: 'https://nv2-maps.firebaseio.com',
  projectId: 'nv2-maps',
  storageBucket: 'nv2-maps.appspot.com',
  messagingSenderId: '1053941326691',
  appId: '1:1053941326691:web:585e2e606f0db9eb1b6956',
  measurementId: 'G-SBZY5GEQ4F',
};

const app = firebase.initializeApp(firebaseConfig);
const firestore = app.firestore();
const rsf = new ReduxSagaFirebase(app);
const auth = app.auth();

firestore.enablePersistence();

export { app, rsf, auth, firestore };
