import React from 'react';
import Joyride from 'react-joyride';

const Tour = () => {
  const steps: any = [
    {
      content: <h2>Sticky elements</h2>,
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 20,
      target: '.lot-name',
    },
  ];

  return (
    <Joyride
      steps={steps}
      run={false}
      scrollToFirstStep={true}
      showProgress={true}
      showSkipButton={true}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
};

export default Tour;
