import React, { useEffect } from 'react';
import { Route, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { Creators as UserCreators } from 'src/store/user';
import { Creators as TenantCreators } from 'src/store/tenant';
import { Creators as PlacesCreators } from 'src/store/places';

import { RootState } from './store';
import Tour from './components/Tour';
import Loader from './components/Loader';
import GoogleMap from './components/Map';
import { Creators } from 'src/store/lots';
import Sidebar from './components/Sidebar';
import { subdomain } from './config/domain';
import DetailsSidebar from './components/Details';
import PlaceDetails from './components/PlaceDetails';
import { Creators as PlaceCreators } from 'src/store/places';

export default function App() {
  const { loading } = useSelector((state: RootState) => state.tenant);
  const logging = useSelector((state: RootState) => state.user.logging);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(TenantCreators.tenantRequest(subdomain));
    dispatch(Creators.lotsRequest());
    dispatch(PlacesCreators.placesRequest());
    dispatch(UserCreators.whatchUser());
  }, [dispatch]);

  if (loading || logging) return <Loader />;

  return (
    <div>
      <Route path="/">
        <Sidebar />
        <GoogleMap />
        <PlaceDetails />
        <DetailsSidebar />
      </Route>
      <Route path="/lote/:lotId">
        <SelectActiveLot />
      </Route>
      <Route path="/place/:placeId">
        <SelectActivePlace />
      </Route>
      <Tour />
    </div>
  );
}

const SelectActiveLot = () => {
  let { lotId } = useParams<{ lotId: string }>();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Creators.setActive(lotId || null));
    dispatch(Creators.setHovered(lotId || ''));
  }, [lotId, dispatch]);
  return null;
};

const SelectActivePlace = () => {
  let { placeId } = useParams<{ placeId: string }>();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(PlaceCreators.setActive(placeId || null));
  }, [placeId, dispatch]);
  return null;
};
