import React from 'react';
import styled from 'styled-components';

const DeleteVideo = ({
  lote,
  handleConfirm,
}: {
  lote: string;
  handleConfirm: () => {};
}) => {
  return (
    <>
      <p>Deseja deletar o video do lote {lote}?</p>
      <Buttons>
        <DeclineButton>Não</DeclineButton>
        <ComfirmButton onClick={handleConfirm}>Sim</ComfirmButton>
      </Buttons>
    </>
  );
};

export default DeleteVideo;

const Buttons = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-around;
`;

const ComfirmButton = styled.button`
  background: green;
  border: none;
  color: white;
  padding: 5px 15px;
  flex: 1;
  margin: 0 5px;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`;

const DeclineButton = styled.button`
  flex: 1;
  background: lightgrey;
  border: none;
  padding: 5px 15px;
  margin: 0 5px;
  cursor: pointer;
  :hover {
    opacity: 0.9;
  }
`;
