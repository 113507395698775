const calcPrice = (valor, prestacoes, juros) => {
  let E, cont;
  juros = juros / 100;
  E = parseFloat('1');
  cont = parseFloat('1');

  for (let k = 1; k <= prestacoes; k++) {
    cont = cont * (1 + juros);
    E = E + cont;
  }
  E = E - cont;
  valor = valor * cont;

  return valor / E;
};
export default calcPrice;
